import {ListOption} from "@components/OptionsList";
import {useButton} from "@react-aria/button";
import {FocusRing} from "@react-aria/focus";
import {AriaMenuOptions, useMenuTrigger} from "@react-aria/menu";
import {useMenuTriggerState} from "@react-stately/menu";
import {MenuTriggerProps} from "@react-types/menu";
import React, {useMemo} from "react";
import {LazyComp} from "src/components/_common/LazyComp";

import {useClientSideId} from "../../../../hooks/useClientSideId";
import MenuPopup from "./MenuPopup";
import {AnchorPosition} from "./types";

export type MenuButtonProps = MenuTriggerProps &
  AriaMenuOptions<ListOption<string>> & {
    renderButton: (
      props: React.ButtonHTMLAttributes<HTMLButtonElement> & {selectedItem?: string},
    ) => JSX.Element;
    anchorDirection: AnchorPosition;
    label: string;
  };

const MenuButton: React.FC<MenuButtonProps> = props => {
  // Create state based on the incoming props
  const state = useMenuTriggerState(props);

  const menuId = useClientSideId();
  const triggerId = useClientSideId();

  // Get props for the menu trigger and menu elements
  const ref = React.useRef();
  // @ts-expect-error TS2345: Argument of type 'MutableRefObject<undefined>' is not assignable to parameter of type 'RefObject<HTMLElement>'.
  const {menuTriggerProps, menuProps} = useMenuTrigger({}, state, ref);

  // Get props for the button based on the trigger props from useMenuTrigger
  // @ts-expect-error TS2769: No overload matches this call.
  const {buttonProps: menuButtonProps} = useButton(menuTriggerProps, ref);

  const {patchedButtonProps, patchedMenuProps} = useMemo(() => {
    return {
      patchedButtonProps: {
        ...menuButtonProps,
        ref,
        id: triggerId,
      },
      patchedMenuProps: {
        ...menuProps,
        id: menuId,
        "aria-labelledby": triggerId,
      },
    };
  }, [menuButtonProps, menuId, menuProps, triggerId]);

  return (
    <div className="pos-r dib">
      <FocusRing focusRingClass="focus-ring">{props.renderButton(patchedButtonProps)}</FocusRing>
      {state.isOpen && (
        <LazyComp>
          <MenuPopup
            {...props}
            domProps={patchedMenuProps}
            autoFocus={state.focusStrategy}
            onClose={() => state.close()}
            aria-label={props.label}
          />
        </LazyComp>
      )}
    </div>
  );
};

export default React.memo(MenuButton);
